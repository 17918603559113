import { useEffect, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { styled } from '@mui/system';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useSubmit from 'libs/hooks/useSubmit';
import useForm from 'libs/hooks/useForm';
import { withSessionSsr } from 'libs/iron-session/withSession';
import URLs from 'data/urls';
import { postFetch } from 'apis/fetcher';
import DRVET_LOGO from 'public/drvet_logo.png';

export const getServerSideProps = withSessionSsr(async function getServerSideProps(context) {
  const { req } = context;
  const token = req.session.token;
  const fetchRes = await fetch(URLs.me, { headers: { Authorization: 'Bearer ' + token } });
  if (fetchRes.status === 200) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
});

const CenterContainer = styled(Stack)({
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
});

const WhiteRound = styled(Stack)({
  width: 400,
  height: 400,
  borderRadius: '50%',
  backgroundColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
});

const FormContainer = styled(Stack)({
  width: '100%',
  padding: '0 24% 0 16%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  gap: 6,
  '& .MuiTextField-root': {
    '&:first-of-type': {
      width: '8ch',
    },
    '&:not(:first-of-type)': {
      width: 'calc(100% - 10ch - 4px)',
    },
  },
});

export default function Login(props) {
  const custIdRef = useRef(null);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const router = useRouter();
  const [isPending, handleSubmit] = useSubmit();
  const [formData, handleChange] = useForm({
    username: '',
    password: '',
    custId: '',
  });

  const handleClick = () =>
    handleSubmit(async () => {
      if (!formData.username || !formData.password || !formData.custId) {
        throw new Error('所有欄位必填');
      }

      try {
        const URL = location.origin;
        const res = await postFetch(URL + '/api/login', { data: formData });
        const data = await res.json();
        if (res.status !== 200) {
          throw new Error(data.message || '操作失敗');
        }

        localStorage.setItem('location', formData.custId);
        setTimeout(() => {
          router.push('/');
        }, 200);
      } catch (err) {
        throw new Error(err.message);
      }
    });

  const handlePressEnter = evt => {
    if (evt.keyCode !== 13) {
      return;
    }

    if (evt.target.name !== 'username' && evt.target.name !== 'custId') {
      if (isPending) return;
      handleClick();
    }

    if (evt.target.name === 'custId') {
      usernameRef.current.focus();
    }

    if (evt.target.name === 'username') {
      passwordRef.current.focus();
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('location')) {
      return;
    }

    const custId = localStorage.getItem('location');
    handleChange({ target: { name: 'custId', value: custId } });

    //eslint-disable-next-line
  }, []);

  return (
    <CenterContainer>
      <WhiteRound>
        <Image width={200} height={200} src={DRVET_LOGO} alt="drvet_logo" loader={({ src }) => src} objectFit="contain" unoptimized />
        <FormContainer>
          <TextField
            size="small"
            label="代碼"
            value={formData.custId}
            name="custId"
            onChange={handleChange}
            onKeyDown={handlePressEnter}
            inputRef={custIdRef}
          />
          <TextField
            variant="standard"
            label="帳號"
            value={formData.username}
            name="username"
            onChange={handleChange}
            onKeyDown={handlePressEnter}
            inputRef={usernameRef}
          />
          <TextField
            variant="standard"
            label="密碼"
            type="password"
            value={formData.password}
            name="password"
            onChange={handleChange}
            onKeyDown={handlePressEnter}
            inputRef={passwordRef}
          />
        </FormContainer>
      </WhiteRound>
      {/* <FormControlLabel control={<Checkbox size="small" />} label="記住帳號密碼" /> */}
      <LoadingButton size="small" variant="contained" loading={isPending} onClick={handleClick}>
        登入
      </LoadingButton>
    </CenterContainer>
  );
}
