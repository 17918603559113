const fetcher = (...args) => fetch(...args).then(res => res.json());

//for login only
export const loginFetch = (url, payload) =>
  fetch(url, {
    method: "POST",
    ...(payload?.data && { body: JSON.stringify(payload?.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload?.auth && { ...payload?.auth }),
    },
  });

export const postFetch = (url, payload) =>
  fetch(url, {
    method: "POST",
    ...(payload?.data && { body: JSON.stringify(payload?.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload?.token && { Authorization: payload?.token }),
    },
  });

export const putFetch = (url, payload) =>
  fetch(url, {
    method: "PUT",
    ...(payload?.data && { body: JSON.stringify(payload?.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload?.token && { Authorization: payload?.token }),
    },
  });

export default fetcher;
